import LayoutA2 from "../../components/layout-a2"
import React from "react"
import EnrollInfoDetail from '../../components/enroll-info-detail'

export default class A2EnrollInfoDetail extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    return(
      <LayoutA2>
        <EnrollInfoDetail  color={color}/>
      </LayoutA2>
    )
  }
}